/* Date tab */

.container{
    height: 40px;
    padding: 2px;
    margin-left: 20px;
    margin-right: 20px;
    border-radius: 8px;
    border-width: 1px;
    border-color: #F0F0F0;
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;
    position: relative;
}

.tab{
    /* width: TAB_WIDTH; */
    height: 100%;
    background-color: #fff;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    padding-top: 2px;
}

.tabText{
    color: #191919;
    font-size: 12px;
    padding-top: 2px;
    z-index: 12;
    text-align: center;
    /* elevation: 2121; */
}

.activeIndicator{
    /* width: TAB_WIDTH; */
    height: 100%;
    background-color: #000;
    border-radius: 4px;
    position: absolute;
    top: -4px;
    left: 2px;
    z-index: 2;
    overflow: hidden;
}

.activeIndicatorText{
    /* line-height: TAB_HEIGHT; */
    font-size: 12px;
    color: #fff;
    z-index: 15;
    padding-top: 10px;
}


.activeIndicatorView{
    /* width: TAB_WIDTH; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start
}
  
/* Date tab */


/* ?::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::.  */


/* Date slider */

.sliderContainer{
    /* width: 100%; */
    padding: 20px;
    padding-top: 0px;
    padding-bottom: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
}

.contentContainerStyle {
    display: flex;
    align-items: center;
    padding: 10px 0;
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
    scroll-behavior: smooth;
    position: relative;
}

.sliderTabView {
    align-items: center;
    cursor: pointer;
}

.sliderTabText{
    color: #fff;
    font-weight: 300;
    text-align: center;
    font-size: 12px;
    opacity: 0.5;
    user-select: none;
}

.sliderActiveIndicator {
    position: absolute;
    background-color: #FFFFFF40;
    top: 4px;
    height: 30px;
    border-radius: 4px;
    z-index: 99;
}
  

/* Date tab */


/* ?::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::.  */


/* Tab bar */

.tabBarContainer{
    height: 88px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.tabBar{
    height: 68px;
    padding: 0 20px;
    background-color: #FFFFFF;
    box-shadow: 0px 0px 8px 2px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.tabBar a{
    padding: 10px 12px;
    border-radius: 8px;
    display: flex;
    align-items: center;
}

.tabBarRoute{
    color: #434343;
    font-size: 12px;
    font-weight: 600;
}

.tabBarIcon{
    width: 20px;
    height: 20px;
}