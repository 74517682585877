body, html{
  background-color: #fff !important;
}

.my-drawer {
  position: relative;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}
.my-drawer .am-drawer-sidebar {
  background-color: #fff;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}
.my-drawer .am-drawer-sidebar .am-list {
  width: 300px;
  padding: 0;
}

.ant-picker-focused, .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector{
    border-color: #000000c4 !important;
    box-shadow: none !important; 
}
.ant-select-item-option-selected:not(.ant-select-item-option-disabled){
  background-color: #adaaab;
}

.ant-picker-range .ant-picker-active-bar{
  background: #414242c7 !important;
}

.sider {
  width: 100%;
  position: absolute;
  left: -121%;
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  height: 100vh;
  top: 0;
  transition: 0.2s ease-in;
  z-index: 1212;
  background-color: white;
}

.header {
  height: 70px;
}

.sider.open {
  left: 0;
}

.sider.open .siderClosable {
  opacity: 1;
  transition: opacity 0.1s ease-in;
}
.sider .siderClosable {
  opacity: 0;
  transition: opacity 0.1s ease-in;
}

.siderContent {
  width: calc(100% - 50px);
  flex: 1;
  padding-top: 30px;
}
.siderClosable {
  width: 50px;
  background-color: rgba(0, 0, 0, 0.4);
}

#dateSlider ::-webkit-scrollbar {
  height: 1px;               /* width of the entire scrollbar */
}

#dateSlider ::-webkit-scrollbar-track {
  background: #ffffff20;        /* color of the tracking area */
}

#dateSlider ::-webkit-scrollbar-thumb {
  background-color: #434343;    /* color of the scroll thumb */
  border-radius: 4px;       /* roundness of the scroll thumb */
}

.spaceBetweenRow{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.ant-spin-dot-item{
  background-color: #000000c4 !important;
}